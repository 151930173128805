<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-card
            class="box-card-area"
            v-for="item in form.questList"
            :key="item.topCategoryOrder"
          >
            <el-form-item>
              <el-input v-model="item.topCategoryName"></el-input>
            </el-form-item>
            <div v-if="item.isSubCategory">
              <div
                v-for="subItem in item.subCategoryList"
                :key="subItem.subCategoryOrder"
              >
                <el-form-item>
                  <el-input v-model="subItem.subCategoryName"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-card
                    class="box-card-question"
                    v-for="question in subItem.questionList"
                    :key="question.guid"
                    style="margin-top:20px"
                  >
                    <div>
                       {{ question.title }}
                       <div v-if="question.types =='填空' ">
                         <el-input></el-input>
                       </div>
                    </div>
                  </el-card>
                </el-form-item>
              </div>
            </div>
            <div v-else>
              <el-form-item>
                <el-card
                  class="box-card-question"
                  v-for="question in item.questionList"
                  :key="question.guid"
                >
                  <div >
                    {{ question.title }}
                  </div>
                </el-card>
              </el-form-item>
            </div>
          </el-card>

          <el-form-item>
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''"
              >删除</el-button
            >
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>
<style>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card-question {
  width: 100%;
}
</style>
<script>
export default {
  data() {
    return {
      form: {
        name:'',
        questList:[]
      },
      userInfo: {},
      allDeptPosition: [],
      rules: {},
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getSurveyDetail(self.$route.query.guid || null);
    self.getQuestionList(self.$route.query.guid || null);
  },
  methods: {
    getSurveyDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/survey/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.form.name = response.data.data.name;
          })
          .catch(function (error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      } else {
        self.form = {
          name: "",
        };
      }
    },
    getQuestionList(surveyGuid) {
      let self = this;
      if (surveyGuid) {
        self.$axios
          .get("/question/list/" + surveyGuid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.form.questList = response.data.data;
          })
          .catch(function (error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      } else {
        self.form = {
          account: "",
          password: "",
        };
      }
    },
    onSubmit() {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(self.form));
          self.$axios
            .post("/survey/saveOrUpdate", JSON.stringify(data), {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function () {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "staff_list" });
            })
            .catch(function (error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的用户信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.account || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/staff/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "staff_list" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>